import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { humanizedTimeSpan, toLocalTime } from '../../libs/libDate';
import { humanFileSize } from '../../libs/libString';
import { getFileExtension } from './getFileExtension';
import { useFilesMemo, useFoldersMemo } from './useFilesQuery';
import { ViewModeProps } from './ViewModeProps';

interface ChildProps {
  children: React.ReactNode;
}

interface ItemProps extends ChildProps {
  onClick?: () => void;
  onDoubleClick?: () => void;
  onRightClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isSelected: boolean;
}

const ListItem: React.FC<ItemProps> = (props) => {
  return <tr className={``} onClick={props.onClick} onDoubleClick={props.onDoubleClick} >
    {props.children}
  </tr>;
}

const ListView: React.FC<ViewModeProps> = (props) => {
  const [search, setSearch] = useSearchParams();
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const folders = useFoldersMemo(props.data);
  const files = useFilesMemo(props.data);

  const setCurrentPath = (path: string): void => {
    console.log(path);
    props.setCurrentPath(path);
  };

  const openFile = (fileId: string) => {
    return () => {
      setSearch((prev) => {
        prev.set('view', fileId);
        return prev;
      });
    };
  };

  return <>
    <div>
      <table className='w-full'>
        <thead>
          <tr>
            <th className='w-4/7 text-left'>File Name</th>
            <th className='w-2/7'>Last Modified</th>
            <th className='w-1/7'>Size</th>
          </tr>
        </thead>
        <tbody>
          {folders.map((folder) => (
            <tr key={folder.name} className={`select-none`} onClick={() => setSelectedItem(folder.name)} onDoubleClick={() => setCurrentPath(`${props.path === '/' ? '' : props.path}/${folder.name}`)}>
              <td><i className='bi bi-folder'></i> <span className={`p-1 ${folder.name === selectedItem ? 'bg-primary text-white' : ''}`}>{folder.name}</span></td>
              <td className='text-center'>{humanizedTimeSpan(toLocalTime(folder.modifiedAt))}</td>
              <td className='text-center'>{humanFileSize(folder.size)}</td>
            </tr>
          ))}
          {files.map((file) => (
            <tr key={file.name} className='select-none' onClick={() => setSelectedItem(file.id)} onDoubleClick={openFile(file.id)}>
              <td><i className={`bi bi-file-earmark bi-filetype-${getFileExtension(file.name)}`}></i> <span className={`p-1 ${file.id === selectedItem ? 'bg-primary text-white' : ''}`}>{file.name}</span></td>
              <td className='text-center'>{humanizedTimeSpan(toLocalTime(file.modifiedTime))}</td>
              <td className='text-center'>{file.size != null ? humanFileSize(file.size as number) : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>;
};

export default ListView;
