import React from 'react';
import { Link, Outlet, useSearchParams } from 'react-router-dom';

import RequirePermission from '../../components/RequirePermission';
import { useAccountContext } from '../../contexts/AccountContext';
import { Permission, PermissionVerb } from '../../libs/libPermissions';
import usePreference from '../../libs/usePreference';
import useTitle from '../../libs/useTitle';
import { FileBrowserViewMode } from '../../types/FileBrowserViewMode';
import FileBrowser from './FileBrowser';
import FilePreview from './FilePreview';
import FileProperties from './FileProperties';
import FileStats from './FileStats';
import Folder from './Folder';

const Files: React.FC = () => {
  const accountContext = useAccountContext();
  const [viewMode, setViewMode] = usePreference<FileBrowserViewMode>('FilesViewMode');
  const [search, setSearch] = useSearchParams();

  useTitle('Files');

  const path = search.get('path') ?? '/';
  const viewFileId = search.get('view');
  const propertiesFileId = search.get('fileProperties');
  const propertiesFolder = search.get('folderProperties');

  const setPath = (path: string): void => {
    setSearch((prev) => {
      prev.set('path', path);
      return prev;
    });
  };

  return <>
    <div className='w-full h-10 bg-background3 flex'>
      <div className='my-auto ml-3 text-lg'>
        <RequirePermission permission={new Permission(PermissionVerb.Create, 'files')}>
          <Link to={`upload?path=${path}`}><i className='bi bi-upload'></i> Upload</Link>
        </RequirePermission>
      </div>
      <div className='my-auto ml-auto mr-3 text-lg'>
        <a onClick={() => setViewMode(FileBrowserViewMode.List)}><i className='bi bi-list'></i> List</a>&nbsp;
        <a onClick={() => setViewMode(FileBrowserViewMode.Icon)}><i className='bi bi-grid'></i> Icon</a>
      </div>
    </div>
    <div className='flex h-[calc(100vh-88px)]'>
      <div className='w-1/5'>
        <div className='h-[calc(100vh-152px)] p-3 select-none'>
          <ul>
            <Folder currentPath={path} displayName={`${accountContext.currentAccount?.name ?? ''} Files`} path='/' setCurrentPath={setPath} expandedByDefault={true} />
          </ul>
        </div>
        <div className='w-full h-16 border-t border-t-background3-darker'>
          <FileStats/>
        </div>
      </div>
      <div className='w-4/5 border-l border-l-background3-darker'>
        <FileBrowser path={path} setCurrentPath={setPath} viewMode={viewMode} />
      </div>
    </div>
    {propertiesFileId != null && <FileProperties fileId={propertiesFileId} />}
    {viewFileId != null && <FilePreview fileId={viewFileId} />}
    <Outlet />
  </>;
};

export default Files;
