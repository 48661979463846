import React from 'react';

import { FilePathOption, FilePathOptionType } from '../../api';
import Input from '../../components/Library/Input';
import Label from '../../components/Library/Label';
import { titleCase } from '../../libs/libString';

interface PathOptionProps {
  option: FilePathOption;
  value: string;
  setValue: (value: string) => void;
}

export type PathOption = React.FC<PathOptionProps>;

const StringOption: PathOption = (props) => {
  return <>
    <div>
      <Label htmlFor={props.option.name}>{titleCase(props.option.name)}</Label>
      <Input id={props.option.name} value={props.value} onChange={(e) => props.setValue(e.target.value)} />
    </div>
  </>;
};

const PathOptionMap: Record<FilePathOptionType, PathOption> = {
  'string': StringOption,
  'month': StringOption,
  'year': StringOption
}

export const PathOptionPrompt: PathOption = (props) => {
  const Option = PathOptionMap[props.option.type];
  return <Option {...props} />;
}