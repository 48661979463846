import { makeApi, Zodios } from "@zodios/core";
import { z } from "zod";

import { useAccountContext } from "./contexts/AccountContext";
import { useUserContext } from "./contexts/UserContext";

export type AccountList = Array<Account>;
export type Account = {
  crmAccountId: string;
  id: string;
  isCustomer?: /**
   * @default true
   */
  boolean | undefined;
  isDemo?: /**
   * @default false
   */
  boolean | undefined;
  isParent?: /**
   * @default false
   */
  boolean | undefined;
  isReferrer?: /**
   * @default false
   */
  boolean | undefined;
  isRoot?: /**
   * @default false
   */
  boolean | undefined;
  isServiceProvider?: /**
   * @default false
   */
  boolean | undefined;
  name: string;
  parentalLineage: (string | null) | Array<string | null>;
  portalStub: (string | null) | Array<string | null>;
  portalUrl: (string | null) | Array<string | null>;
  providerLineage: (string | null) | Array<string | null>;
  referralLineage: (string | null) | Array<string | null>;
  serviceProviderId: (string | null) | Array<string | null>;
  serviceProviderName: (string | null) | Array<string | null>;
  serviceProviderStub: (string | null) | Array<string | null>;
};
export type ApiCredential = {
  clientId: string;
  name: string;
  portalRole: PortalRole;
};
export type PortalRole =
  /**
   * Temp
   *
   * @enum global_super_admin, global_admin, admin, support, sales, demo, user
   */
  | "global_super_admin"
  | "global_admin"
  | "admin"
  | "support"
  | "sales"
  | "demo"
  | "user";
export type ApiCredentialList = Array<ApiCredential>;
export type AuthUserInfoResponse = {
  account: UserInfoAccount;
  email: (string | null) | Array<string | null>;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  portal_url: string;
  service_provider: UserInfoServiceProvider;
  sub: string;
  updated_at?: ((string | null) | Array<string | null>) | undefined;
  whitedog_app_metadata: LegacyMetadataRoot;
};
export type UserInfoAccount = {
  id: string;
  is_customer: boolean;
  is_parent: boolean;
  is_referrer: boolean;
  is_root: boolean;
  is_service_provider: boolean;
  name: string;
  role: string;
};
export type UserInfoServiceProvider = {
  account_id: (string | null) | Array<string | null>;
  account_name: (string | null) | Array<string | null>;
};
export type LegacyMetadataRoot = {
  user_info: LegacyMetadataUserInfo;
};
export type LegacyMetadataUserInfo = {
  account: UserInfoAccount;
  contact_id: string;
  gimp: boolean;
  id: string;
  portalUrl: string;
  role: string;
  service_provider: UserInfoServiceProvider;
};
export type BarChartWidgetConfigration = {
  bars: Array<BarChartWidgetBar>;
  showGrid: (boolean | null) | Array<boolean | null>;
  showLegend: (boolean | null) | Array<boolean | null>;
  showTooltip: (boolean | null) | Array<boolean | null>;
  useUniformBarColor: (boolean | null) | Array<boolean | null>;
  xAxisLabelKey: (string | null) | Array<string | null>;
  yAxisLabelKey: (string | null) | Array<string | null>;
};
export type BarChartWidgetBar = {
  colorKey: (string | null) | Array<string | null>;
  dataKey: string;
  label: (string | null) | Array<string | null>;
};
export type Case = {
  caseType: CaseType;
  category: CaseCategory;
  createdAt: string;
  createdById: string;
  createdByName: string;
  description: string;
  id: string;
  modifiedAt: string;
  origin: CaseOrigin;
  priority: CasePriority;
  severity: CaseSeverity;
  state: CaseState;
  status: CaseStatus;
  ticketNumber: string;
  title: string;
};
export type CaseType =
  /**
   * @enum question, problem, request, feedback, feature, incident
   */
  "question" | "problem" | "request" | "feedback" | "feature" | "incident";
export type CaseCategory =
  /**
   * @enum soc, helpdesk, noc, support, feedback, billing, licensing, hardware
   */
  | "soc"
  | "helpdesk"
  | "noc"
  | "support"
  | "feedback"
  | "billing"
  | "licensing"
  | "hardware";
export type CaseOrigin =
  /**
   * @enum phone, email, web, alert, portal, api, iot
   */
  "phone" | "email" | "web" | "alert" | "portal" | "api" | "iot";
export type CasePriority =
  /**
   * @enum critical, high, normal, low
   */
  "critical" | "high" | "normal" | "low";
export type CaseSeverity =
  /**
   * @enum moderate, major, critical, minor
   */
  "moderate" | "major" | "critical" | "minor";
export type CaseState =
  /**
   * @enum active, resolved, cancelled
   */
  "active" | "resolved" | "cancelled";
export type CaseStatus =
  /**
   * @enum wip, hold, woc, wop, solved, cancelled, answered, duplicate, quarantine, new, completed, reopen
   */
  | "wip"
  | "hold"
  | "woc"
  | "wop"
  | "solved"
  | "cancelled"
  | "answered"
  | "duplicate"
  | "quarantine"
  | "new"
  | "completed"
  | "reopen";
export type CaseDetails = {
  caseLog: Array<CaseLogEntry>;
  caseType: CaseType;
  category: CaseCategory;
  createdAt: string;
  createdById: string;
  createdByName: string;
  description: string;
  id: string;
  modifiedAt: string;
  origin: CaseOrigin;
  priority: CasePriority;
  severity: CaseSeverity;
  state: CaseState;
  status: CaseStatus;
  ticketNumber: string;
  title: string;
};
export type CaseLogEntry = {
  createdAt: string;
  createdById: string;
  createdByName: string;
  description: string;
  fromName: string;
  htmlDescription: string;
  id: string;
  modifiedAt: string;
  modifiedById: string;
  modifiedByName: string;
  subject: string;
};
export type CaseList = Array<Case>;
export type CreateCase = {
  caseType: CaseType;
  category: CaseCategory;
  description: string;
  origin: CaseOrigin;
  priority: CasePriority;
  severity: CaseSeverity;
  title: string;
};
export type CreateFile = {
  category: string;
  classification?:
    | ((FileClassification | null) | Array<FileClassification | null>)
    | undefined;
  fileMetadata?: (({} | null) | Array<{} | null>) | undefined;
  name: string;
  pathMetadata: {};
  upsert?: /**
   * If false will return an error if the file already exists. If true will return the existing file making any changes.
   *
   * @default false
   */
  boolean | undefined;
};
export type FileClassification =
  /**
   * @enum 0, 10, 20, 30, 40
   */
  0 | 10 | 20 | 30 | 40;
export type DashboardWidget = {
  configuration:
    | (
        | PieChartWidgetConfiguration
        | BarChartWidgetConfigration
        | TableWidgetConfiguration
      )
    | Array<
        | PieChartWidgetConfiguration
        | BarChartWidgetConfigration
        | TableWidgetConfiguration
      >;
  data: (Array<{}> | null) | Array<Array<{}> | null>;
  label: string;
  type: WidgetType;
};
export type PieChartWidgetConfiguration = {
  pies: Array<PieChartWidgetPie>;
  showLegend: (boolean | null) | Array<boolean | null>;
  showTooltip: (boolean | null) | Array<boolean | null>;
};
export type PieChartWidgetPie = {
  data: Array<PieChartWidgetDataEntry>;
};
export type PieChartWidgetDataEntry = {
  color: (FrontendColor | string | null) | Array<FrontendColor | string | null>;
  label: string;
  value: number;
};
export type FrontendColor =
  /**
   * @enum critical, high, medium, low, success, warning, danger, info, nav, light, dark, primary, secondary
   */
  | "critical"
  | "high"
  | "medium"
  | "low"
  | "success"
  | "warning"
  | "danger"
  | "info"
  | "nav"
  | "light"
  | "dark"
  | "primary"
  | "secondary";
export type TableWidgetConfiguration = {
  columns: Array<DataColumn>;
};
export type DataColumn = {
  default: (boolean | null) | Array<boolean | null>;
  displayName: string;
  enableFilter: (boolean | null) | Array<boolean | null>;
  enableSort: (boolean | null) | Array<boolean | null>;
  name: string;
  sortInverted: (boolean | null) | Array<boolean | null>;
  type: DataColumnType;
  width: (number | null) | Array<number | null>;
};
export type DataColumnType =
  /**
   * @enum string, humandatetime, datetime, boolean, number, currency, percentage, mood
   */
  | "string"
  | "humandatetime"
  | "datetime"
  | "boolean"
  | "number"
  | "currency"
  | "percentage"
  | "mood";
export type WidgetType =
  /**
   * @enum pie, bar, table, scoreboard, simple_table
   */
  "pie" | "bar" | "table" | "scoreboard" | "simple_table";
export type DataPrintSystem = {
  Inspector: DataPrintSystemInspector;
  Launchpoint: DataPrintSystemLaunchpoint;
};
export type DataPrintSystemInspector = {
  Alias: string;
  ID: number;
};
export type DataPrintSystemLaunchpoint = {
  NextScheduledFor: string;
};
export type DataPrintView = {
  ViewDefinition: ViewDefinition;
};
export type ViewDefinition = {
  Views: Array<ViewDefinitionView>;
};
export type ViewDefinitionView = {
  Items: Array<ViewDefinitionViewItem>;
  Title: string;
  ViewType: (string | null) | Array<string | null>;
};
export type ViewDefinitionViewItem = {
  Columns: (Array<string> | null) | Array<Array<string> | null>;
  DefaultSorted: (Array<SortField> | null) | Array<Array<SortField> | null>;
  InitialColumns:
    | (Array<InitialColumn> | null)
    | Array<Array<InitialColumn> | null>;
  Label: string;
  Path: (string | null) | Array<string | null>;
  Rows: (Array<Array<string>> | null) | Array<Array<Array<string>> | null>;
  Type: string;
};
export type SortField = {
  desc: boolean;
  id: string;
};
export type InitialColumn = {
  name: string;
  show: boolean;
};
export type DirectoryListing = Array<(File | Folder) | Array<File | Folder>>;
export type File = {
  createdTime: string;
  fileMetadata?: (({} | null) | Array<{} | null>) | undefined;
  id: string;
  mimeType?: ((string | null) | Array<string | null>) | undefined;
  modifiedTime: string;
  name: string;
  path: string;
  pathMetadata: {};
  size?: ((number | null) | Array<number | null>) | undefined;
  totalArchiveSize: number;
  totalLiveSize: number;
  type?:
    | /**
     * @default "file"
     * @enum file
     */
    "file"
    | undefined;
};
export type Folder = {
  modifiedAt: string;
  name: string;
  size: number;
  totalArchiveSize: number;
  totalLiveSize: number;
  type?:
    | /**
     * @default "folder"
     * @enum folder
     */
    "folder"
    | undefined;
};
export type FileCategory = {
  defaultClassification: FileClassification;
  id: string;
  name: string;
  path: string;
  pathOptions: Array<FilePathOption>;
};
export type FilePathOption = {
  name: string;
  parameters: {};
  type: FilePathOptionType;
};
export type FilePathOptionType =
  /**
   * @enum string, year, month
   */
  "string" | "year" | "month";
export type FileCategoryList = Array<FileCategory>;
export type FileVersionList = Array<FileVersion>;
export type FileVersion = {
  id: string;
};
export type NewApiCredential = {
  clientId: string;
  clientSecret: string;
  name: string;
  portalRole: PortalRole;
};
export type NodeDataPrint = {
  charts: Array<DashboardWidget>;
  node: NodeInfo;
  view: DataPrintView;
  views: {};
};
export type NodeInfo = {
  defaultTable: string;
  environmentId: number;
  inspectorAlias: string;
  inspectorId: number;
  label: string;
  name: string;
  nodeId: number;
};
export type NodeList = Array<NodeListItem>;
export type NodeListItem = {
  id: number;
  inspectorAlias: string;
  label: string;
  name: string;
};
export type SystemDataPrint = {
  system: DataPrintSystem;
  view: DataPrintView;
  views: {};
};
export type SystemList = Array<SystemListItem>;
export type SystemListItem = {
  id: number;
  inspectorAlias: string;
  name: string;
};
export type TreeNode = {
  children: (Array<TreeNode> | null) | Array<Array<TreeNode> | null>;
  className?: ((string | null) | Array<string | null>) | undefined;
  label: string;
  value: (string | null) | Array<string | null>;
};
export type TreeNodeList = Array<TreeNode>;
export type User = {
  accountId: string;
  accountIsCustomer: boolean;
  accountIsDemo: boolean;
  accountIsParent: boolean;
  accountIsReferrer: boolean;
  accountIsRoot: boolean;
  accountIsServiceProvider: boolean;
  accountName: string;
  accountState: number;
  email: (string | null) | Array<string | null>;
  firstName: string;
  gimp: boolean;
  id: string;
  lastName: string;
  mobilePhone: (string | null) | Array<string | null>;
  name: string;
  officePhone: (string | null) | Array<string | null>;
  permissions: Array<string>;
  portalRole: (PortalRole | null) | Array<PortalRole | null>;
  portalUrl: (string | null) | Array<string | null>;
  serviceProviderId: (string | null) | Array<string | null>;
  serviceProviderName: (string | null) | Array<string | null>;
};

const AuthUserInfoRequest = z
  .object({
    authProvider: z.union([z.string(), z.null()]),
    authProviderUserId: z.union([z.string(), z.null()]),
    email: z.union([z.string(), z.null()]),
    wdUserId: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough();
const UserInfoAccount: z.ZodType<UserInfoAccount> = z
  .object({
    id: z.string().uuid(),
    is_customer: z.boolean(),
    is_parent: z.boolean(),
    is_referrer: z.boolean(),
    is_root: z.boolean(),
    is_service_provider: z.boolean(),
    name: z.string(),
    role: z.string(),
  })
  .passthrough();
const UserInfoServiceProvider: z.ZodType<UserInfoServiceProvider> = z
  .object({
    account_id: z.union([z.string(), z.null()]),
    account_name: z.union([z.string(), z.null()]),
  })
  .passthrough();
const LegacyMetadataUserInfo: z.ZodType<LegacyMetadataUserInfo> = z
  .object({
    account: UserInfoAccount,
    contact_id: z.string().uuid(),
    gimp: z.boolean(),
    id: z.string().uuid(),
    portalUrl: z.string(),
    role: z.string(),
    service_provider: UserInfoServiceProvider,
  })
  .passthrough();
const LegacyMetadataRoot: z.ZodType<LegacyMetadataRoot> = z
  .object({ user_info: LegacyMetadataUserInfo })
  .passthrough();
const AuthUserInfoResponse: z.ZodType<AuthUserInfoResponse> = z
  .object({
    account: UserInfoAccount,
    email: z.union([z.string(), z.null()]),
    email_verified: z.boolean(),
    family_name: z.string(),
    given_name: z.string(),
    name: z.string(),
    portal_url: z.string(),
    service_provider: UserInfoServiceProvider,
    sub: z.string().uuid(),
    updated_at: z.union([z.string(), z.null()]).optional(),
    whitedog_app_metadata: LegacyMetadataRoot,
  })
  .passthrough();
const ValidationErrorModel = z
  .object({
    ctx: z
      .union([z.object({}).partial().passthrough(), z.null()])
      .describe(
        "an optional object which contains values required to render the error message."
      ),
    loc: z
      .union([z.array(z.string()), z.null()])
      .describe("the error's location as a list. "),
    msg: z
      .union([z.string(), z.null()])
      .describe("a computer-readable identifier of the error type."),
    type_: z
      .union([z.string(), z.null()])
      .describe("a human readable explanation of the error."),
  })
  .partial()
  .passthrough();
const ClientCredentials = z
  .object({ client_id: z.string().uuid(), client_secret: z.string() })
  .passthrough();
const PortalRole = z.enum([
  "global_super_admin",
  "global_admin",
  "admin",
  "support",
  "sales",
  "demo",
  "user",
]);
const User: z.ZodType<User> = z
  .object({
    accountId: z.string().uuid(),
    accountIsCustomer: z.boolean(),
    accountIsDemo: z.boolean(),
    accountIsParent: z.boolean(),
    accountIsReferrer: z.boolean(),
    accountIsRoot: z.boolean(),
    accountIsServiceProvider: z.boolean(),
    accountName: z.string(),
    accountState: z.number().int(),
    email: z.union([z.string(), z.null()]),
    firstName: z.string(),
    gimp: z.boolean(),
    id: z.string().uuid(),
    lastName: z.string(),
    mobilePhone: z.union([z.string(), z.null()]),
    name: z.string(),
    officePhone: z.union([z.string(), z.null()]),
    permissions: z.array(z.string()),
    portalRole: z.union([PortalRole, z.null()]),
    portalUrl: z.union([z.string(), z.null()]),
    serviceProviderId: z.union([z.string(), z.null()]),
    serviceProviderName: z.union([z.string(), z.null()]),
  })
  .passthrough();
const Account: z.ZodType<Account> = z
  .object({
    crmAccountId: z.string().uuid(),
    id: z.string().uuid(),
    isCustomer: z.boolean().optional().default(true),
    isDemo: z.boolean().optional().default(false),
    isParent: z.boolean().optional().default(false),
    isReferrer: z.boolean().optional().default(false),
    isRoot: z.boolean().optional().default(false),
    isServiceProvider: z.boolean().optional().default(false),
    name: z.string(),
    parentalLineage: z.union([z.string(), z.null()]),
    portalStub: z.union([z.string(), z.null()]),
    portalUrl: z.union([z.string(), z.null()]),
    providerLineage: z.union([z.string(), z.null()]),
    referralLineage: z.union([z.string(), z.null()]),
    serviceProviderId: z.union([z.string(), z.null()]),
    serviceProviderName: z.union([z.string(), z.null()]),
    serviceProviderStub: z.union([z.string(), z.null()]),
  })
  .passthrough();
const AccountList: z.ZodType<AccountList> = z.array(Account);
const x_wd_account = z
  .union([z.string(), z.null()])
  .describe(
    "Whitedog Account ID. Used to retrieve data for account you have access to that are not your account"
  )
  .optional();
const ApiCredential: z.ZodType<ApiCredential> = z
  .object({
    clientId: z.string().uuid(),
    name: z.string(),
    portalRole: PortalRole.describe("Temp"),
  })
  .passthrough();
const ApiCredentialList: z.ZodType<ApiCredentialList> = z.array(ApiCredential);
const CreateApiCredential = z.object({ name: z.string() }).passthrough();
const NewApiCredential: z.ZodType<NewApiCredential> = z
  .object({
    clientId: z.string().uuid(),
    clientSecret: z.string(),
    name: z.string(),
    portalRole: PortalRole.describe("Temp"),
  })
  .passthrough();
const PatchApiCredential = z
  .object({ name: z.union([z.string(), z.null()]) })
  .passthrough();
const BrandResponse = z
  .object({
    appName: z.string(),
    apps: z.array(z.string()),
    brandLogo: z.string(),
    brandName: z.string(),
    brandPath: z.string(),
    navLogo: z.string(),
    themeName: z.string(),
  })
  .passthrough();
const BrandPatch = z
  .object({
    appName: z.union([z.string(), z.null()]),
    apps: z.union([z.array(z.string()), z.null()]),
    brandLogoRaw: z
      .union([z.string(), z.null()])
      .describe("Base64 encoded image"),
    brandName: z.union([z.string(), z.null()]),
    navLogoRaw: z
      .union([z.string(), z.null()])
      .describe("Base64 encoded image"),
    themeName: z.union([z.string(), z.null()]),
  })
  .partial()
  .passthrough();
const BrandPut = z
  .object({
    appName: z.string(),
    apps: z.union([z.array(z.string()), z.null()]).optional(),
    brandLogoRaw: z
      .union([z.string(), z.null()])
      .describe("Base64 encoded image")
      .optional(),
    brandName: z.string(),
    navLogoRaw: z
      .union([z.string(), z.null()])
      .describe("Base64 encoded image")
      .optional(),
    themeName: z.string().optional().default("whitedog"),
  })
  .passthrough();
const q = z
  .union([z.string(), z.null()])
  .describe("RQL Query to use to search for cases.");
const CaseType = z.enum([
  "question",
  "problem",
  "request",
  "feedback",
  "feature",
  "incident",
]);
const CaseCategory = z.enum([
  "soc",
  "helpdesk",
  "noc",
  "support",
  "feedback",
  "billing",
  "licensing",
  "hardware",
]);
const CaseOrigin = z.enum([
  "phone",
  "email",
  "web",
  "alert",
  "portal",
  "api",
  "iot",
]);
const CasePriority = z.enum(["critical", "high", "normal", "low"]);
const CaseSeverity = z.enum(["moderate", "major", "critical", "minor"]);
const CaseState = z.enum(["active", "resolved", "cancelled"]);
const CaseStatus = z.enum([
  "wip",
  "hold",
  "woc",
  "wop",
  "solved",
  "cancelled",
  "answered",
  "duplicate",
  "quarantine",
  "new",
  "completed",
  "reopen",
]);
const Case: z.ZodType<Case> = z
  .object({
    caseType: CaseType,
    category: CaseCategory,
    createdAt: z.string().datetime({ offset: true }),
    createdById: z.string().uuid(),
    createdByName: z.string(),
    description: z.string(),
    id: z.string().uuid(),
    modifiedAt: z.string().datetime({ offset: true }),
    origin: CaseOrigin,
    priority: CasePriority,
    severity: CaseSeverity,
    state: CaseState,
    status: CaseStatus,
    ticketNumber: z.string(),
    title: z.string(),
  })
  .passthrough();
const CaseList: z.ZodType<CaseList> = z.array(Case);
const CreateCase: z.ZodType<CreateCase> = z
  .object({
    caseType: CaseType,
    category: CaseCategory,
    description: z.string(),
    origin: CaseOrigin,
    priority: CasePriority,
    severity: CaseSeverity,
    title: z.string(),
  })
  .passthrough();
const CaseLogEntry: z.ZodType<CaseLogEntry> = z
  .object({
    createdAt: z.string().datetime({ offset: true }),
    createdById: z.string().uuid(),
    createdByName: z.string(),
    description: z.string(),
    fromName: z.string(),
    htmlDescription: z.string(),
    id: z.string().uuid(),
    modifiedAt: z.string().datetime({ offset: true }),
    modifiedById: z.string().uuid(),
    modifiedByName: z.string(),
    subject: z.string(),
  })
  .passthrough();
const CaseDetails: z.ZodType<CaseDetails> = z
  .object({
    caseLog: z.array(CaseLogEntry),
    caseType: CaseType,
    category: CaseCategory,
    createdAt: z.string().datetime({ offset: true }),
    createdById: z.string().uuid(),
    createdByName: z.string(),
    description: z.string(),
    id: z.string().uuid(),
    modifiedAt: z.string().datetime({ offset: true }),
    origin: CaseOrigin,
    priority: CasePriority,
    severity: CaseSeverity,
    state: CaseState,
    status: CaseStatus,
    ticketNumber: z.string(),
    title: z.string(),
  })
  .passthrough();
const brand = z.union([z.string(), z.null()]).optional();
const File: z.ZodType<File> = z
  .object({
    createdTime: z.string().datetime({ offset: true }),
    fileMetadata: z
      .union([z.object({}).partial().passthrough(), z.null()])
      .optional(),
    id: z.string().uuid(),
    mimeType: z.union([z.string(), z.null()]).optional(),
    modifiedTime: z.string().datetime({ offset: true }),
    name: z.string(),
    path: z.string(),
    pathMetadata: z.object({}).partial().passthrough(),
    size: z.union([z.number(), z.null()]).optional(),
    totalArchiveSize: z.number().int(),
    totalLiveSize: z.number().int(),
    type: z.literal("file").optional().default("file"),
  })
  .passthrough();
const Folder: z.ZodType<Folder> = z
  .object({
    modifiedAt: z.string().datetime({ offset: true }),
    name: z.string(),
    size: z.number().int(),
    totalArchiveSize: z.number().int(),
    totalLiveSize: z.number().int(),
    type: z.literal("folder").optional().default("folder"),
  })
  .passthrough();
const DirectoryListing: z.ZodType<DirectoryListing> = z.array(
  z.union([File, Folder])
);
const FileClassification = z.union([
  z.literal(0),
  z.literal(10),
  z.literal(20),
  z.literal(30),
  z.literal(40),
]);
const CreateFile: z.ZodType<CreateFile> = z
  .object({
    category: z.string().uuid(),
    classification: z.union([FileClassification, z.null()]).optional(),
    fileMetadata: z
      .union([z.object({}).partial().passthrough(), z.null()])
      .optional(),
    name: z.string(),
    pathMetadata: z.object({}).partial().passthrough(),
    upsert: z
      .boolean()
      .describe(
        "If false will return an error if the file already exists. If true will return the existing file making any changes."
      )
      .optional()
      .default(false),
  })
  .passthrough();
const FilePathOptionType = z.enum(["string", "year", "month"]);
const FilePathOption: z.ZodType<FilePathOption> = z
  .object({
    name: z.string(),
    parameters: z.object({}).partial().passthrough(),
    type: FilePathOptionType,
  })
  .passthrough();
const FileCategory: z.ZodType<FileCategory> = z
  .object({
    defaultClassification: FileClassification,
    id: z.string().uuid(),
    name: z.string(),
    path: z.string(),
    pathOptions: z.array(FilePathOption),
  })
  .passthrough();
const FileCategoryList: z.ZodType<FileCategoryList> = z.array(FileCategory);
const FileStats = z
  .object({
    archiveFileCount: z.number().int(),
    liveFileCount: z.number().int(),
    totalFileCount: z.number().int(),
    totalSize: z.number().int(),
    totalUsed: z.number().int(),
    usedArchive: z.number().int(),
    usedLive: z.number().int(),
  })
  .passthrough();
const FileUrl = z.object({ url: z.string() }).passthrough();
const FileVersion: z.ZodType<FileVersion> = z
  .object({ id: z.string().uuid() })
  .passthrough();
const FileVersionList: z.ZodType<FileVersionList> = z.array(FileVersion);
const FileVersionUploadResponse = z
  .object({
    id: z.string().uuid(),
    virusScanJobId: z.union([z.string(), z.null()]),
  })
  .passthrough();
const JobStatus = z
  .object({
    id: z.string(),
    result: z.union([z.unknown(), z.null()]),
    status: z.string(),
  })
  .passthrough();
const NodeListItem: z.ZodType<NodeListItem> = z
  .object({
    id: z.number().int(),
    inspectorAlias: z.string(),
    label: z.string(),
    name: z.string(),
  })
  .passthrough();
const NodeList: z.ZodType<NodeList> = z.array(NodeListItem);
const FrontendColor = z.enum([
  "critical",
  "high",
  "medium",
  "low",
  "success",
  "warning",
  "danger",
  "info",
  "nav",
  "light",
  "dark",
  "primary",
  "secondary",
]);
const PieChartWidgetDataEntry: z.ZodType<PieChartWidgetDataEntry> = z
  .object({
    color: z.union([FrontendColor, z.string(), z.null()]),
    label: z.string(),
    value: z.number(),
  })
  .passthrough();
const PieChartWidgetPie: z.ZodType<PieChartWidgetPie> = z
  .object({ data: z.array(PieChartWidgetDataEntry) })
  .passthrough();
const PieChartWidgetConfiguration: z.ZodType<PieChartWidgetConfiguration> = z
  .object({
    pies: z.array(PieChartWidgetPie),
    showLegend: z.union([z.boolean(), z.null()]),
    showTooltip: z.union([z.boolean(), z.null()]),
  })
  .passthrough();
const BarChartWidgetBar: z.ZodType<BarChartWidgetBar> = z
  .object({
    colorKey: z.union([z.string(), z.null()]),
    dataKey: z.string(),
    label: z.union([z.string(), z.null()]),
  })
  .passthrough();
const BarChartWidgetConfigration: z.ZodType<BarChartWidgetConfigration> = z
  .object({
    bars: z.array(BarChartWidgetBar),
    showGrid: z.union([z.boolean(), z.null()]),
    showLegend: z.union([z.boolean(), z.null()]),
    showTooltip: z.union([z.boolean(), z.null()]),
    useUniformBarColor: z.union([z.boolean(), z.null()]),
    xAxisLabelKey: z.union([z.string(), z.null()]),
    yAxisLabelKey: z.union([z.string(), z.null()]),
  })
  .passthrough();
const DataColumnType = z.enum([
  "string",
  "humandatetime",
  "datetime",
  "boolean",
  "number",
  "currency",
  "percentage",
  "mood",
]);
const DataColumn: z.ZodType<DataColumn> = z
  .object({
    default: z.union([z.boolean(), z.null()]),
    displayName: z.string(),
    enableFilter: z.union([z.boolean(), z.null()]),
    enableSort: z.union([z.boolean(), z.null()]),
    name: z.string(),
    sortInverted: z.union([z.boolean(), z.null()]),
    type: DataColumnType,
    width: z.union([z.number(), z.null()]),
  })
  .passthrough();
const TableWidgetConfiguration: z.ZodType<TableWidgetConfiguration> = z
  .object({ columns: z.array(DataColumn) })
  .passthrough();
const WidgetType = z.enum([
  "pie",
  "bar",
  "table",
  "scoreboard",
  "simple_table",
]);
const DashboardWidget: z.ZodType<DashboardWidget> = z
  .object({
    configuration: z.union([
      PieChartWidgetConfiguration,
      BarChartWidgetConfigration,
      TableWidgetConfiguration,
    ]),
    data: z.union([z.array(z.object({}).partial().passthrough()), z.null()]),
    label: z.string(),
    type: WidgetType,
  })
  .passthrough();
const NodeInfo: z.ZodType<NodeInfo> = z
  .object({
    defaultTable: z.string(),
    environmentId: z.number().int(),
    inspectorAlias: z.string(),
    inspectorId: z.number().int(),
    label: z.string(),
    name: z.string(),
    nodeId: z.number().int(),
  })
  .passthrough();
const SortField: z.ZodType<SortField> = z
  .object({ desc: z.boolean(), id: z.string() })
  .passthrough();
const InitialColumn: z.ZodType<InitialColumn> = z
  .object({ name: z.string(), show: z.boolean() })
  .passthrough();
const ViewDefinitionViewItem: z.ZodType<ViewDefinitionViewItem> = z
  .object({
    Columns: z.union([z.array(z.string()), z.null()]),
    DefaultSorted: z.union([z.array(SortField), z.null()]),
    InitialColumns: z.union([z.array(InitialColumn), z.null()]),
    Label: z.string(),
    Path: z.union([z.string(), z.null()]),
    Rows: z.union([z.array(z.array(z.string())), z.null()]),
    Type: z.string(),
  })
  .passthrough();
const ViewDefinitionView: z.ZodType<ViewDefinitionView> = z
  .object({
    Items: z.array(ViewDefinitionViewItem),
    Title: z.string(),
    ViewType: z.union([z.string(), z.null()]),
  })
  .passthrough();
const ViewDefinition: z.ZodType<ViewDefinition> = z
  .object({ Views: z.array(ViewDefinitionView) })
  .passthrough();
const DataPrintView: z.ZodType<DataPrintView> = z
  .object({ ViewDefinition: ViewDefinition })
  .passthrough();
const NodeDataPrint: z.ZodType<NodeDataPrint> = z
  .object({
    charts: z.array(DashboardWidget),
    node: NodeInfo,
    view: DataPrintView,
    views: z.record(
      z.array(z.union([z.array(z.array(z.unknown())), z.null()]))
    ),
  })
  .passthrough();
const SystemListItem: z.ZodType<SystemListItem> = z
  .object({
    id: z.number().int(),
    inspectorAlias: z.string(),
    name: z.string(),
  })
  .passthrough();
const SystemList: z.ZodType<SystemList> = z.array(SystemListItem);
const DataPrintSystemInspector: z.ZodType<DataPrintSystemInspector> = z
  .object({ Alias: z.string(), ID: z.number().int() })
  .passthrough();
const DataPrintSystemLaunchpoint: z.ZodType<DataPrintSystemLaunchpoint> = z
  .object({ NextScheduledFor: z.string() })
  .passthrough();
const DataPrintSystem: z.ZodType<DataPrintSystem> = z
  .object({
    Inspector: DataPrintSystemInspector,
    Launchpoint: DataPrintSystemLaunchpoint,
  })
  .passthrough();
const SystemDataPrint: z.ZodType<SystemDataPrint> = z
  .object({
    system: DataPrintSystem,
    view: DataPrintView,
    views: z.record(
      z.array(z.union([z.array(z.array(z.unknown())), z.null()]))
    ),
  })
  .passthrough();
const UserResponse = z.object({}).partial().passthrough();
const TreeNode: z.ZodType<TreeNode> = z.lazy(() =>
  z
    .object({
      children: z.union([z.array(TreeNode), z.null()]),
      className: z.union([z.string(), z.null()]).optional(),
      label: z.string(),
      value: z.union([z.string(), z.null()]),
    })
    .passthrough()
);
const TreeNodeList: z.ZodType<TreeNodeList> = z.array(TreeNode);

export const schemas = {
  AuthUserInfoRequest,
  UserInfoAccount,
  UserInfoServiceProvider,
  LegacyMetadataUserInfo,
  LegacyMetadataRoot,
  AuthUserInfoResponse,
  ValidationErrorModel,
  ClientCredentials,
  PortalRole,
  User,
  Account,
  AccountList,
  x_wd_account,
  ApiCredential,
  ApiCredentialList,
  CreateApiCredential,
  NewApiCredential,
  PatchApiCredential,
  BrandResponse,
  BrandPatch,
  BrandPut,
  q,
  CaseType,
  CaseCategory,
  CaseOrigin,
  CasePriority,
  CaseSeverity,
  CaseState,
  CaseStatus,
  Case,
  CaseList,
  CreateCase,
  CaseLogEntry,
  CaseDetails,
  brand,
  File,
  Folder,
  DirectoryListing,
  FileClassification,
  CreateFile,
  FilePathOptionType,
  FilePathOption,
  FileCategory,
  FileCategoryList,
  FileStats,
  FileUrl,
  FileVersion,
  FileVersionList,
  FileVersionUploadResponse,
  JobStatus,
  NodeListItem,
  NodeList,
  FrontendColor,
  PieChartWidgetDataEntry,
  PieChartWidgetPie,
  PieChartWidgetConfiguration,
  BarChartWidgetBar,
  BarChartWidgetConfigration,
  DataColumnType,
  DataColumn,
  TableWidgetConfiguration,
  WidgetType,
  DashboardWidget,
  NodeInfo,
  SortField,
  InitialColumn,
  ViewDefinitionViewItem,
  ViewDefinitionView,
  ViewDefinition,
  DataPrintView,
  NodeDataPrint,
  SystemListItem,
  SystemList,
  DataPrintSystemInspector,
  DataPrintSystemLaunchpoint,
  DataPrintSystem,
  SystemDataPrint,
  UserResponse,
  TreeNode,
  TreeNodeList,
};

export const RestrictedEndpoints = makeApi([
  {
    method: "post",
    path: "/rapi/v1/auth-user-info",
    alias: "rapi_v1_auth_user_info_rapi_v1_auth_user_info_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AuthUserInfoRequest,
      },
    ],
    response: AuthUserInfoResponse,
    errors: [
      {
        status: 401,
        description: `Unauthorized`,
        schema: z.void(),
      },
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/rapi/v1/auth/client_credentials",
    alias:
      "rapi_v1_auth_client_credentials_rapi_v1_auth_client_credentials_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ClientCredentials,
      },
    ],
    response: User,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/rapi/v1/auth0_token",
    alias: "rapi_v1_auth0_token_rapi_v1_auth0_token_get",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "get",
    path: "/rapi/v1/portal_urls",
    alias: "rapi_v1_portal_urls_rapi_v1_portal_urls_get",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "get",
    path: "/rapi/v1/user-by-email/:email",
    alias: "rapi_v1_user_by_email_rapi_v1_user_by_email__email__get",
    requestFormat: "json",
    parameters: [
      {
        name: "email",
        type: "Path",
        schema: z.string().describe("User's Email to Lookup"),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/rapi/v1/webhooks/case/created",
    alias: "rapi_v1_webhooks_case_created_rapi_v1_webhooks_case_created_post",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "post",
    path: "/rapi/v1/webhooks/case/note/created",
    alias:
      "rapi_v1_webhooks_case_note_created_rapi_v1_webhooks_case_note_created_post",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "post",
    path: "/rapi/v1/webhooks/case/updated",
    alias: "rapi_v1_webhooks_case_updated_rapi_v1_webhooks_case_updated_post",
    requestFormat: "json",
    response: z.void(),
  },
]);

export const useRestrictedApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, RestrictedEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const AccountEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/accounts",
    alias: "v1_accounts_v1_accounts_get",
    requestFormat: "json",
    response: z.array(Account),
  },
  {
    method: "get",
    path: "/v1/accounts/:account_id",
    alias: "v1_accounts_account_id_v1_accounts__account_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "account_id",
        type: "Path",
        schema: z.string().uuid().describe("Account ID"),
      },
    ],
    response: Account,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useAccountApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, AccountEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const AdminEndpoints = makeApi([
  {
    method: "post",
    path: "/v1/admin/datasheet_overlay",
    alias: "v1_admin_datasheet_overlay_v1_admin_datasheet_overlay_post",
    requestFormat: "json",
    response: z.void(),
  },
]);

export const useAdminApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, AdminEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const Api_CredentialsEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/api-credentials",
    alias: "get_api_credentials",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: z.array(ApiCredential),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/v1/api-credentials",
    alias: "create_new_api_credential",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ name: z.string() }).passthrough(),
      },
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: NewApiCredential,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "delete",
    path: "/v1/api-credentials/:api_credential_id",
    alias: "archive_api_credential",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "api_credential_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/api-credentials/:api_credential_id",
    alias: "get_api_credential",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "api_credential_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ApiCredential,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "patch",
    path: "/v1/api-credentials/:api_credential_id",
    alias: "update_api_credential",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: PatchApiCredential,
      },
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "api_credential_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: ApiCredential,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/v1/api-credentials/:api_credential_id/regenerate-secret",
    alias: "regenerate_secret_for_api_credential",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "api_credential_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: NewApiCredential,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useApi_CredentialsApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, Api_CredentialsEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const BrandsEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/brands/self",
    alias: "v1_brands_self_get_v1_brands_self_get",
    requestFormat: "json",
    response: BrandResponse,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "patch",
    path: "/v1/brands/self",
    alias: "v1_brands_self_patch_v1_brands_self_patch",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: BrandPatch,
      },
    ],
    response: BrandResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "put",
    path: "/v1/brands/self",
    alias: "v1_brands_self_put_v1_brands_self_put",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: BrandPut,
      },
    ],
    response: BrandResponse,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useBrandsApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, BrandsEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const CasesEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/cases",
    alias: "v1_cases_v1_cases_get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "q",
        type: "Query",
        schema: q,
      },
    ],
    response: z.array(Case),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/v1/cases",
    alias: "v1_cases_create_v1_cases_post",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateCase,
      },
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: CaseDetails,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/cases/:case_id",
    alias: "v1_cases_case_id_v1_cases__case_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "case_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: CaseDetails,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useCasesApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, CasesEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const Data_SheetsEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/datasheet/:product.pdf",
    alias: "v1_datasheet_v1_datasheet__product__pdf_get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "product",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "brand",
        type: "Query",
        schema: brand,
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useData_SheetsApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, Data_SheetsEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const EntitlementsEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/entitlements/component-portals",
    alias:
      "v1_entitlements_componet_portals_v1_entitlements_component_portals_get",
    requestFormat: "json",
    response: z.void(),
  },
  {
    method: "get",
    path: "/v1/product-catalog/apps",
    alias: "v1_product_catalog_apps_v1_product_catalog_apps_get",
    requestFormat: "json",
    response: z.void(),
  },
]);

export const useEntitlementsApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, EntitlementsEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const FilesEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/files",
    alias: "get_directory_listing_by_path",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "path",
        type: "Query",
        schema: z.string().optional().default("/"),
      },
    ],
    response: DirectoryListing,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/v1/files",
    alias: "create_new_file",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateFile,
      },
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: File,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/categories",
    alias: "get_file_categories",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: z.array(FileCategory),
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/v1/files/categories",
    alias: "create_file_category",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: z.array(FileCategory),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "delete",
    path: "/v1/files/categories/:file_category_id",
    alias: "delete_file_category",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_category_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: FileCategory,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/categories/:file_category_id",
    alias: "get_file_category",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_category_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: FileCategory,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "patch",
    path: "/v1/files/categories/:file_category_id",
    alias: "update_file_category",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_category_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: FileCategory,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/content",
    alias: "v1_files_content_v1_files_content_get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "path",
        type: "Query",
        schema: z.string().optional().default("/"),
      },
    ],
    response: File,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/folder",
    alias: "get_folder_info_by_path",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "path",
        type: "Query",
        schema: z.string().optional().default("/"),
      },
    ],
    response: Folder,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/stats",
    alias: "get_stats",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: FileStats,
    errors: [
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "delete",
    path: "/v1/files/:file_id",
    alias: "delete_file",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/:file_id",
    alias: "get_file_info",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: File,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "patch",
    path: "/v1/files/:file_id",
    alias: "update_file_info",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: File,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/:file_id/content",
    alias: "get_file_content",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "download",
        type: "Query",
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/:file_id/content-url",
    alias: "get_file_content_url",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "ttl",
        type: "Query",
        schema: z.number().int().gte(0).lte(3600).optional().default(60),
      },
      {
        name: "download",
        type: "Query",
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.object({ url: z.string() }).passthrough(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/:file_id/versions",
    alias: "get_file_versions",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.array(FileVersion),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "post",
    path: "/v1/files/:file_id/versions",
    alias: "create_new_file_version",
    description: `Use this to upload a version of a file. The content of the file you want to upload should be the body of this request.`,
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "body",
        type: "Body",
        schema: z.instanceof(window.File),
      },
    ],
    response: FileVersionUploadResponse,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "delete",
    path: "/v1/files/:file_id/versions/:version_id",
    alias: "delete_file_version",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "version_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/:file_id/versions/:version_id",
    alias: "get_file_version_info",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "version_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.object({ id: z.string().uuid() }).passthrough(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/:file_id/versions/:version_id/content",
    alias: "get_file_version_content",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "version_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "download",
        type: "Query",
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.array(FileVersion),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/files/:file_id/versions/:version_id/content-url",
    alias: "get_file_version_content_url",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "file_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "version_id",
        type: "Path",
        schema: z.string().uuid(),
      },
      {
        name: "ttl",
        type: "Query",
        schema: z.number().int().gte(0).lte(3600).optional().default(60),
      },
      {
        name: "download",
        type: "Query",
        schema: z.boolean().optional().default(false),
      },
    ],
    response: z.object({ url: z.string() }).passthrough(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useFilesApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, FilesEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const JobsEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/jobs/:job_id",
    alias: "get_status_by_id",
    requestFormat: "json",
    parameters: [
      {
        name: "job_id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: JobStatus,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useJobsApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, JobsEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const NodesEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/nodes",
    alias: "v1_nodes_v1_nodes_get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: z.array(NodeListItem),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/nodes/:node_id",
    alias: "v1_nodes_node_id_v1_nodes__node_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "node_id",
        type: "Path",
        schema: z.number().int(),
      },
    ],
    response: NodeDataPrint,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useNodesApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, NodesEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const SystemsEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/systems",
    alias: "v1_systems_v1_systems_get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: z.array(SystemListItem),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/systems/:system_id",
    alias: "v1_systems_system_id_v1_systems__system_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
      {
        name: "system_id",
        type: "Path",
        schema: z.number().int().describe("System Id"),
      },
    ],
    response: SystemDataPrint,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useSystemsApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, SystemsEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const UsersEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/user/me",
    alias: "v1_user_me_v1_user_me_get",
    description: `Only available for users authenticated with Auth0`,
    requestFormat: "json",
    response: User,
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "get",
    path: "/v1/user/:user_id",
    alias: "v1_user_user_id_v1_user__uuid_user_id__get",
    requestFormat: "json",
    parameters: [
      {
        name: "user_id",
        type: "Path",
        schema: z.string().uuid(),
      },
    ],
    response: z.object({}).partial().passthrough(),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useUsersApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, UsersEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};

export const UXEndpoints = makeApi([
  {
    method: "get",
    path: "/v1/ux/accountstree",
    alias: "v1_ux_account_tree_v1_ux_accountstree_get",
    description: `Gets an account tree. Used by WD Portal for Account Dropdown`,
    requestFormat: "json",
    parameters: [
      {
        name: "x-wd-account",
        type: "Header",
        schema: x_wd_account,
      },
    ],
    response: z.array(TreeNode),
    errors: [
      {
        status: 404,
        description: `Not Found`,
        schema: z.void(),
      },
      {
        status: 422,
        description: `Unprocessable Entity`,
        schema: z.array(ValidationErrorModel),
      },
    ],
  },
]);

export const useUXApi = () => {
  const userContext = useUserContext();
  const accountContext = useAccountContext();

  return new Zodios(import.meta.env.VITE_API_URI, UXEndpoints, {
    axiosConfig: {
      headers: {
        Authorization: `Bearer ${userContext.accessToken}`,
        "X-WD-Account": accountContext.accountId,
        "X-WD-Email": userContext.apparentUserEmail,
      },
    },
  });
};
